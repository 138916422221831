import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
const Text = styled.div`
  font-weight: 100;
  font-size: 35px;
  color: var(--accentVeryLight);
  font-family: "Urbanist", sans-serif;
`;
const Icon = styled.img`
  height: 50px;
  width: 50px;
  object-fit: cover;
`;

export default function WhyChooseUsLine({ children }) {
  return (
    <Container>
      <Icon src="sphere.png" />
      <Text>{children}</Text>
    </Container>
  );
}
