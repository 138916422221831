import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext, useRef } from "react";
import Context from "../../Context.js";
import BrandContainer from "../brand/BrandContainer.js";
import CustomButton from "../helperComponents/CustomButton.js";
import WhyChooseUs from "./loggedOut/WhyChooseUs.js";
import TrendingInternshipPrograms from "./loggedOut/TrendingInternshipPrograms.js";
import CompanyStat from "./loggedOut/CompanyStat.js";
import Testimonials from "./loggedOut/Testimonials.js";
import AlumniWorkAt from "./loggedOut/AlumniWorkAt.js";
import PrestigiousPartners from "./loggedOut/PrestigiousPartners.js";
import TalkToExpert from "./loggedOut/TalkToExpert.js";
import Footer from "./loggedOut/Footer.js";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 65px;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 35px;
  }
`;
const MainButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Medium = styled.div`
  font-size: 21px;
  font-weight: 600;
  opacity: 0.5;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  margin-top: 10px;
`;

let linkVariant = "minimal";

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90vw;
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 90px;
  width: 90vw;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 10px 20px;
  border-radius: 10px;
  padding-left: 40px;
  background: var(--mainGradient);
`;

const AnimatedHeroImage = styled.img`
  width: 700px;
`;

const mainButtonStyle = {
  background: "var(--mainGradient)",
  color: "#111",
  boxShadow: "rgb(0 0 0 / 14%) red",
};

const btnStyle = {
  color: "#111",
};

const btnStyle2 = {
  color: "var(--accentColor)",
  backgroundColor: "#111",
};

export default function LoggedOutHomePage() {
  const containerRef = useRef();

  return (
    <ParentContainer ref={containerRef}>
      <TopPart>
        <BrandContainer />

        <Links>
          <CustomButton variant={linkVariant} style={btnStyle}>
            Home
          </CustomButton>
          <CustomButton variant={linkVariant} style={btnStyle}>
            Courses
          </CustomButton>
          <CustomButton variant={linkVariant} style={btnStyle}>
            Membership
          </CustomButton>
          <CustomButton variant={linkVariant} style={btnStyle}>
            About Us
          </CustomButton>
          <CustomButton variant={linkVariant} style={btnStyle}>
            Contact Us
          </CustomButton>
          {/* <CustomButton variant="filled" style={btnStyle2}>
            Login / Signup
          </CustomButton> */}
        </Links>
      </TopPart>

      <Main>
        <HeroSection>
          <TextContainer>
            <Tagline>Learning Done Right</Tagline>
            <Medium>
              Unlock Your Peak Potential With Our Quality Courses, Internship
              Program & Industry Focused Projects
            </Medium>
            <br />
            <MainButtons>
              <CustomButton
                style={mainButtonStyle}
                height={"40px"}
                width={"200px"}
                onClick={goToAuthScreen}
              >
                Enroll
              </CustomButton>
            </MainButtons>
          </TextContainer>
          <AnimatedHeroImage src="/hero.gif" />
        </HeroSection>
        {containerRef.current ? (
          <>
            <WhyChooseUs containerRef={containerRef} />
            <TrendingInternshipPrograms containerRef={containerRef} />
            {/* <CompanyStat containerRef={containerRef} /> */}
            {/* <Testimonials containerRef={containerRef} /> */}
            {/* <AlumniWorkAt containerRef={containerRef} /> */}
            {/* <PrestigiousPartners containerRef={containerRef} /> */}
            <TalkToExpert containerRef={containerRef} />
            <Footer containerRef={containerRef} />
          </>
        ) : null}
      </Main>
    </ParentContainer>
  );
}
