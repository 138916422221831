import styled from "styled-components";
import InternshipProgramCard from "./InternshipProgramCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Fugaz One", sans-serif;
  color: var(--accentLight);
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;
`;

const List = styled.div`
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
`;

export default function TrendingInternshipPrograms({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0.3 1", "0.7 1"],
  });

  return (
    <Container ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>TRENDING INTERNSHIP PROGRAMS</Title>
          <Description>
            Want to be a part of the most trending and exciting internships
            today? Check out our trending internship programs today.
          </Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          <InternshipProgramCard
            gradient="linear-gradient(183deg, #4CAF50, rgb(0 19 40))"
            filter="hue-rotate(222deg)"
            image="web-dev"
            title="Web Development"
            description="Computer Science"
          />
          <InternshipProgramCard
            filter=""
            gradient="linear-gradient(1deg, rgb(12 13 14), rgb(85 0 213))"
            image="machine-learning"
            title="Machine Learning"
            description="Computer Science"
          />
          <InternshipProgramCard
            filter="hue-rotate(273deg)"
            gradient="linear-gradient(247deg, rgb(146 0 255), rgb(0 0 0))"
            image="autocad"
            title="AutoCAD"
            description="Mechanical / Civil"
          />
          <InternshipProgramCard
            filter=""
            gradient="linear-gradient(7deg, rgb(0, 0, 0), rgb(96 32 216))"
            image="iot"
            title="Internet Of Things"
            description="Electronics & Communication"
          />
          <InternshipProgramCard
            filter="hue-rotate(360deg)"
            gradient="linear-gradient(15deg, rgb(0 0 0), rgb(255 66 160))"
            image="marketing"
            title="Marketing"
            description="Business"
          />
          <InternshipProgramCard
            filter="hue-rotate(271deg)"
            gradient="linear-gradient(26deg, rgb(0 0 0), rgb(115 0 255))"
            image="motion-graphics"
            title="Motion Graphics"
            description="Design"
          />
        </List>
      </motion.div>
    </Container>
  );
}
