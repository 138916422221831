import styled from "styled-components";

const Container = styled.div`
  width: 400px;
  background-color: var(--mainGradient);
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.93);
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 90%;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 80%;
`;

const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 22px;
  font-family: "Fugaz One", sans-serif;
`;

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  opacity: 0.7;
`;

export default function InternshipProgramCard({
  image,
  title,
  description,
  gradient,
  filter,
}) {
  return (
    <Container style={{ background: gradient }}>
      <Image
        style={{ filter }}
        src={`/trending-internship-programs/${image}.png`}
      />

      <Text>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Text>
    </Container>
  );
}
